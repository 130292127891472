import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { okaoAxios } from '../../helpers/axios';
import { RootState, store } from '../../redux/store';
import { Summary } from '../Restaurant/types';
import { MenuRequest } from './types'


export interface IMenuRequestSlice {
  menuRequests?: MenuRequest[] | undefined;
  status: string
  count: number
  pageSize: number
  countByStatus: Summary[] | undefined
}

const initialState: IMenuRequestSlice = {
  menuRequests: undefined,
  pageSize: 100,
  status: 'idle',
  count: 0,
  countByStatus: undefined
};

export const fetchMenuRequests = createAsyncThunk('api/fetchNeedsMenus', async (page: number) => {
  const auth = store.getState().secure.accessToken ?? '';
  const response = await okaoAxios(auth).get(`${process.env.REACT_APP_DIS_URL ?? window.location.origin}/menu/menu-load-request/${page}?size=${store.getState().menuRequests.pageSize}`)
  return response;
})

export const menuRequestsSlice = createSlice({
  name: 'menuRequests',
  initialState,
  reducers: {
    setMenuRequests: (state, action: PayloadAction<MenuRequest[] | undefined>) => {
        state.menuRequests = action.payload
    },
    setPageSize: (state, action: PayloadAction<number>) => {
      state.pageSize = action.payload
    }  
  },
  extraReducers: builder => {
    builder.addCase(fetchMenuRequests.pending, (state, action) => {
      state.status = 'loading'
    })
    .addCase(fetchMenuRequests.fulfilled, (state, action) => {
      state.menuRequests = action.payload.data.result
      state.count = action.payload.data.count
      state.status = 'idle'
      state.countByStatus = action.payload.data.countByStatus
    })
    .addCase(fetchMenuRequests.rejected, (state, action) => {
      state.status = 'error'
    })
  }
});

export const selectMenuRequests = (state: RootState): MenuRequest[] => state.menuRequests.menuRequests ?? []
export const selectRowCount = (state: RootState): number => state.menuRequests.count
export const selectPageSize = (state: RootState): number => state.menuRequests.pageSize
export const selectIsLoading = (state: RootState): boolean => state.menuRequests.status === "loading"
export const selectCountByStatus = (state: RootState): Summary[] | undefined => state.menuRequests.countByStatus

export const { setMenuRequests, setPageSize } = menuRequestsSlice.actions;
export default menuRequestsSlice.reducer
