import styled from "styled-components";

export const Page = styled.div`
    background-color: #f4f5fa;
    font-family: 'Montserrat';
    width: 90rem;
    height: 64rem;
`

export const LeftNavigation = styled.div`
    position: absolute;
    width: 5.5rem;
    height: 64rem;
    left: 0;
    top: 0;

    background: #FFFFFF;
`

export const TopNavigation = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0;

    position: absolute;
    width: 84.5rem;
    height: 3.75rem;
    left: 5.5rem;
    top: 0;

    /* White */

    background: #FFFFFF;
`

export const MenuLoadRequest = styled.img.attrs(props => ({
    src: "/images/missing-menu.svg"
}))`
    width: 2.3rem;
    height: 3.5rem;

    border-radius: 0.75rem;

    /* Inside auto layout */

    flex: none;
    order: 1;
    flex-grow: 0;
    cursor: pointer;
    padding: 0 10px;
`

export const Locations = styled.img.attrs(props => ({
    src: "/images/location.svg"
}))`
    width: 2.3rem;
    height: 3.5rem;

    border-radius: 0.75rem;

    /* Inside auto layout */

    flex: none;
    order: 1;
    flex-grow: 0;
    cursor: pointer;
    padding: 0 10px;
`

export const Eaters = styled.img.attrs(props => ({
    src: "/images/eaters.svg"
}))`
    width: 2.3rem;
    height: 3.5rem;

    border-radius: 0.75rem;

    /* Inside auto layout */

    flex: none;
    order: 1;
    flex-grow: 0;
    cursor: pointer;
    padding: 0 10px;
`

export const RestaurantRequests = styled.img.attrs(props => ({
    src: "/images/partnership.svg"
}))`
    width: 2.3rem;
    height: 3.5rem;

    border-radius: 0.75rem;

    /* Inside auto layout */

    flex: none;
    order: 1;
    flex-grow: 0;
    cursor: pointer;
    padding: 0 10px;
`

export const UnblockUsers = styled.img.attrs(props => ({
  src: "/images/ban-user.svg"
}))`
    width: 2.3rem;
    height: 3.5rem;

    border-radius: 0.75rem;

    /* Inside auto layout */

    flex: none;
    order: 1;
    flex-grow: 0;
    cursor: pointer;
    padding: 0 10px;
`

export const Access = styled.img.attrs(props => ({
    src: "/images/access-control.svg"
}))`
    width: 2.3rem;
    height: 3.5rem;

    border-radius: 0.75rem;

    /* Inside auto layout */

    flex: none;
    order: 1;
    flex-grow: 0;
    cursor: pointer;
    padding: 0 10px;
`

export const Admin = styled.img.attrs(props => ({
    src: "/images/admin.svg"
}))`
    width: 2.3rem;
    height: 3.5rem;

    border-radius: 0.75rem;

    /* Inside auto layout */

    flex: none;
    order: 1;
    flex-grow: 0;
    cursor: pointer;
    padding: 0 10px;
    padding-top: 5px;
`

export const Logo = styled.img.attrs(props => ({
    src: "/logo.svg"
  }))`
    position: absolute
    width: 4.3rem;
    height: 4.3rem;
    left: 0.75rem;
    top: 0.5625rem;
    cursor: pointer;
  `;

  export const Logout = styled.img.attrs(props => ({
    src: "/images/logout.svg"
  }))`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0.6875rem;
    gap: 0.625rem;
    
    width: 2.3rem;
    height: 3.5rem;
    
    border-radius: 16px;
    
    /* Inside auto layout */
    
    flex: none;
    order: 0;
    flex-grow: 0;
    cursor: pointer;
  `;

  export const Bottom = styled.div`
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 0;
    gap: 0.75rem;
    
    position: absolute;
    width: 2.875rem;
    height: 2.875rem;
    left: 1.3125rem;
    top: 58.8125rem;  
  `

  export const MenuItems = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0;
    gap: 0.75rem;
    
    position: absolute;
    width: 3.5rem;
    height: 24.75rem;
    left: 1rem;
    top: 8rem;  
  `

export const NavContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 0;
    gap: 57.5rem;

    width: 81.875rem;
    height: 2rem;


    /* Inside auto layout */

    flex: none;
    order: 0;
    align-self: stretch;
    flex-grow: 0;

    padding: 1.5rem 3.5rem 0 1rem;
`

export const PageLabel = styled.div`
    width: auto;
    height: 1.5rem;

    font-style: normal;
    font-weight: 500;
    font-size: 1.25rem;
    line-height: 1.5rem;

    /* Primary */

    color: #1F2041;


    /* Inside auto layout */

    flex: none;
    order: 0;
    flex-grow: 0;
`

export const Profile = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0;
    gap: 1.25rem;

    width: 81.875rem;
    height: 2rem;


    /* Inside auto layout */

    flex: none;
    order: 1;
    flex-grow: 0;
`

interface Notifications {
    count: number
}

export const Notification = styled.div<Notifications>`
    width: 1.25rem;
    height: 1.25rem;

    background: url(${props => props.count === 0 ? '/images/bell.svg' : '/images/notification.svg'});
    background-repeat: no-repeat;
    background-size: 1.25rem 1.25rem;
    /* Inside auto layout */

    flex: none;
    order: 0;
    flex-grow: 0;
`

interface UserProfile {
    profile?: string
}

export const User = styled.div<UserProfile>`
    width: 2rem;
    height: 2rem;

    background: url(${props => props.profile !== '' ? props.profile : '/images/profile.svg'});
    background-repeat: no-repeat;
    background-size: 2rem 2rem;
    border-radius: 16px;

    /* Inside auto layout */

    flex: none;
    order: 1;
    flex-grow: 0;
    cursor: pointer;
`

export const Breadcrumb = styled.div`
    box-sizing: border-box;

    /* Auto layout */

    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0.25rem 1.25rem;
    gap: 0.625rem;

    width: 84.5rem;
    height: 1.5rem;

    /* White */

    background: #FFFFFF;
    /* Stroke */

    border-top: 0.0625rem solid #F1F3F9;

    /* Inside auto layout */

    flex: none;
    order: 1;
    align-self: stretch;
    flex-grow: 0;
`

export const Children = styled.div`
    position: absolute;
    left: 6.75rem;
    top: 5.25rem;
    width: 81.8125rem;
    height: auto;
`

export const HomeIconContainer = styled.div``


export const Element = styled.div``


export const HighLightContainer = styled.div``


export const HighLightButton = styled.div`
    background-color: #FFF3DE;
    border-radius: 7px;
`
