/* eslint-disable */

import React, { useEffect, useState } from 'react';
import PageContainer from '../../components/PageContainer/PageContainer';
import {
  fetchMenuRequests,
  selectCountByStatus,
  selectMenuRequests,
  selectPageSize,
  selectRowCount,
  setPageSize
} from './slice';
import { selectCurrentRestaurant, setCurrentRestaurant } from '../Restaurant/slice';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { GridCallbackDetails, GridColDef, GridEventListener, GridRowParams, MuiEvent } from '@mui/x-data-grid';
import { Container } from './MenuLoadRequest.styled';
import { GridRowProps } from 'semantic-ui-react';
import Grid from '../../components/Grid/Grid';
import { EditToolbar } from '../../components/EditToolbar/EditToolbar';
import { randomId } from '@mui/x-data-grid-generator';

import DashboardSummary, { Item } from '../../components/DashboardSummary/DashboardSummary';
import { Summary } from '../Dashboard/Dashboard.styled';
import { withAuthenticationRequired } from '@auth0/auth0-react';
import { useNavigate } from 'react-router-dom';


const MenuLoadRequest: React.FC = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const menuRequestsServer = useAppSelector(selectMenuRequests);
  const [menuRequests, setMenuRequests] = useState(menuRequestsServer);
  const rowCount = useAppSelector(selectRowCount);
  const pageSize = useAppSelector(selectPageSize);
  const countByStatus = useAppSelector(selectCountByStatus);
  const currentRestaurant = useAppSelector(selectCurrentRestaurant);

  useEffect(() => {
    dispatch(fetchMenuRequests(0));
  }, [])

  useEffect(()=>{
    setMenuRequests(menuRequestsServer);
  },[menuRequestsServer]);

  const rows: GridRowProps[] = menuRequests.map(menuRequest => {
      return {
        id: menuRequest.loadMenuRequestId,
        menuID: menuRequest.menuDTO.menuID,
        restaurantID: menuRequest.restaurant.restaurantID,
        restaurantName: menuRequest.restaurant.name,
        restaurant: menuRequest.restaurant,
        date: menuRequest.restaurant.created,
        name: menuRequest.menuDTO.name,
        location: `${menuRequest.restaurant.city}, ${menuRequest.restaurant.state}`,
        status: menuRequest.status
      }
  });


  const getRowID = (row: any) => {
    return row.id;
  }

  const id = randomId();
  const empty = {
    id,
    menuID: id,
    restaurantID: '',
    restaurantName: '',
    restaurant: {},
    name: '',
    date: '',
    location: "",
    status: ''
  }
  
  const columns: GridColDef[] = [
    { field: 'restaurantName', headerName: 'Restaurant Name', width: 250 },
    { field: 'name', headerName: 'Menu Name', width: 250 },
    { field: 'date', headerName: 'Created Date', width: 150 },
    { field: 'location', headerName: 'Location', width: 150 },
    { field: 'status', headerName: 'Status', width: 150 },
  ];

  const statuses: Item[] = countByStatus?.map((city) => {
    const item: Item = {
                        name: city.name,
                        value: ''+city.count
                      }
      return item;
    })??[]

    const [open, setOpen] = useState(false);

  const handleRowClick: GridEventListener<'rowClick'> = (
    params: GridRowParams,
    event: MuiEvent<React.MouseEvent<HTMLElement>>,
    details: GridCallbackDetails
  ) => {
    const outerHTML = (event.target as any).outerHTML
    if(outerHTML.indexOf('div') === 1){
      dispatch(setCurrentRestaurant(params.row.restaurant));
      navigate(`/restaurant/${params.row.restaurant.alias}/menu/${params.row.menuID}`)
    }
  };
    
  return (
      <PageContainer title='Menu Load Requests' open={open} setOpen={setOpen}>
        <Container>
        <Summary>
          <DashboardSummary items={statuses}/>
        </Summary>
          <Grid 
            rows={rows} 
            totalRowCount={rowCount}
            dataColumns={columns} 
            editable={false}
            saveFn={(newRow) => {
              /* do nothing */
            }}
            paginationServer={true}
            nextPageFn={(newPage: number) => {dispatch(fetchMenuRequests(newPage))}}
            rowClickedFn={handleRowClick}
            deleteFn={(id: string) => {/* do nothing */}}
            setRows={setMenuRequests}
            EditToolbar={EditToolbar}
            empty={empty}
            getRowId={getRowID}
            pageSize={pageSize}
            pageSizeOptions={[25,50,100]}
            setPageSize={(pageSize: number) => {dispatch(setPageSize(pageSize))}}
            />
        </Container>
      </PageContainer>
  )
}

export default withAuthenticationRequired(MenuLoadRequest)
