export const ROUTES = {
    HOMEPAGE_ROUTE: '/',
    LOGIN_CALLBACK_ROUTE: '/callback',
    PROFILE_ROUTE: '/profile',
    DASHBOARD_ROUTE: '/dashboard',
    MENU_LOAD_REQUEST_ROUTE: '/menu-load-request',
    LOCATION_ROUTE: '/locations',
    ADD_LOCATION_ROUTE: '/locations/add-location',
    ADD_NEW_MENU_ROUTE: '/add-new-menu',
    RESTAURANT_ROUTE: '/restaurant',
    MENU_ROUTE: '/menu',
    REVIEW_ROUTE: '/reviews',
    PRESS_ROUTE: '/press',
    EATERS_ROUTE: '/eaters',
    ADD_NEW_EATER_ROUTE: '/add-eater',
    DINING_REVIEW_ROUTE: '/dining-reviews',
    UNAUTHORIZED_ROUTE: '/unauthorized',
    REQUEST_ACCESS_ROUTE: '/request-access',
    PENDING_ACCESS_ROUTE: '/pending-access',
    ADMIN_REQUESTS_ROUTE: '/access-control',
    ADMINS_ROUTE: '/admins',
    ONBOARDING_RESTAURANT_REQUEST_ROUTE: '/onboarding-restaurant-requests',
    UNBLOCK_USERS: '/unblock-users'
}