/* eslint-disable */

import React, {useState} from 'react'
import PageContainer  from '../../components/PageContainer/PageContainer';
import { addNewMenu } from './slice';
import { useAppSelector, useAppDispatch } from '../../redux/hooks';
import { AddDishContainer, AddDishTitle, AddDishContentHeader, AddDishContentBody } from './Menu.styled';
import { selectCurrentRestaurant, setOpen, selectShouldOpen, selectMessage, selectSeverity } from '../Restaurant/slice';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import { useParams } from 'react-router-dom';
import { BreadCrumbLink } from '../../components/PageContainer/PageContainer';
import { NewMenuRequest } from '../MenuLoadRequest/types';
import { withAuthenticationRequired } from '@auth0/auth0-react';

const AddMenu: React.FC = () => {
  const dispatch = useAppDispatch();
  const[ name, setName ] = useState('');
  const[ url, setUrl ] = useState('');
  const { alias } = useParams();
  const restaurant = useAppSelector(selectCurrentRestaurant);

  const breadcrumbLinks: BreadCrumbLink[] = [
    {
      name: restaurant?.name??'', 
      url:`/restaurant/${alias}`
    }
  ]

  const openServer = useAppSelector(selectShouldOpen);
  const message = useAppSelector(selectMessage);
  const severity = useAppSelector(selectSeverity);

  const setOpenServer = (open: boolean) => {
    dispatch(setOpen(open));
  }
    
  return (
    <PageContainer title="Add Menu" breadcrumbs={breadcrumbLinks} open={openServer} setOpen={setOpenServer} message={message} severity={severity}>
    <AddDishContainer>
      <AddDishContentHeader>
        <AddDishTitle>Details</AddDishTitle>
          <Stack direction="row" spacing={2}>
            <Button variant="text" onClick={() => {
              const dishRequest: NewMenuRequest = {
                name: name,
                url: url,
                restaurantId: restaurant?.restaurantID??''
              }
              dispatch(addNewMenu(dishRequest));
              setName('');
              setUrl('');
            }}>Save</Button>
          </Stack>
      </AddDishContentHeader>
      <AddDishContentBody>
        <Stack direction="row" spacing={5}>
          <TextField id="name" label="Menu Name" variant="outlined" value={name} onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
            setName(event.target.value);
          }}/>
          <TextField id="url" label="URL" variant="outlined" value={url} onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
            setUrl(event.target.value);
          }}/>
        </Stack>
        </AddDishContentBody>
      </AddDishContainer>
    </PageContainer>
  )
}

export default withAuthenticationRequired(AddMenu)
